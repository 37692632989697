@import '../../common.scss';

.alert-item-row {
  display: flex;
  flex-direction: column;

  & > .alert-item-details-row {
    display: flex;

    & > .alert-item-details-col {
      flex: 1;

      & > .alert-item-row-stats {
        display: flex;

        & > .item-stat {
          margin-right: 10px;
        }
      }

      .tag {
        font-style: italic;
        margin-right: 5px;
        font-weight: 600;
      }
    }

    & > .view-report-col {
      display: flex;
      align-items: center;

      button.label {
        transform: translateY(-20px);
        flex-direction: column;
        display: flex;
        height: auto;
        padding: 0;
        align-items: center;
        border: none;
        background: transparent;
        cursor: pointer;
        text-transform: none;
        box-shadow: none;
        color: $color-subtext;
        .icon {
          font-size: 18px;
        }
        span {
          font-size: 10px;
        }
      }
    }
  }

  border-left: 10px solid lightgray;
  &.severity-01 {
    border-color: $color-alarm1;
  }
  &.severity-02 {
    border-color: $color-alarm2;
  }
  &.severity-03 {
    border-color: $color-alarm3;
  }
  &.severity-04 {
    border-color: $color-alarm4;
  }
}
