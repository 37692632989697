// Grid
$grid-columns: 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

// Fonts
$font-primary: 'Lato', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-arial: Arial, sans-serif;
$font-lato: 'Lato', sans-serif;

// Color
$color-subtext: #9fa2b4;
$color-blue: #2f80ed;
$color-red: #f57878;
$color-black: #202020;
$color-white: #ffffff;

$color-whitesmoke: #FAFAFA;
$color-grey01: #fafafa;
$color-grey02: #f0f0f0;
$color-grey03: #E0E0E0;
$color-grey04: #828282;
$color-grey05: #3a3a3a;
$color-alarm1: #E0E0E0;
$color-alarm2: #FBCF60;
$color-alarm3: #F7A365;
$color-alarm4: #F57878;
$color-msg-scheduled: #f5f5f5;
$color-msg-sent: #fbcf60;
$color-msg-delivered: #68c690;
$color-msg-read: #68c690;
$color-msg-responded: #6da6f2;
$color-msg-escalated: #f57878;
$color-msg-sms: #f57878;

// Typography

// text
.text-grey01 {
  color: $color-grey01;
}
.text-grey02 {
  color: $color-grey02;
}
.text-grey03 {
  color: $color-grey03;
}
.text-grey04 {
  color: $color-grey04;
}

// Common
.lm1 {
  margin-left: 1px !important;
}
.lm2 {
  margin-left: 2px !important;
}
.lm3 {
  margin-left: 3px !important;
}
.lm4 {
  margin-left: 4px !important;
}
.lm5 {
  margin-left: 5px !important;
}
.lm10 {
  margin-left: 10px !important;
}
.lm15 {
  margin-left: 15px !important;
}
.lm20 {
  margin-left: 20px !important;
}
.lm30 {
  margin-left: 30px !important;
}
.lm40 {
  margin-left: 40px !important;
}
.lm60 {
  margin-left: 60px !important;
}
.lm80 {
  margin-left: 80px !important;
}

.rm1 {
  margin-right: 1px !important;
}
.rm2 {
  margin-right: 2px !important;
}
.rm3 {
  margin-right: 3px !important;
}
.rm4 {
  margin-right: 4px !important;
}
.rm5 {
  margin-right: 5px !important;
}
.rm10 {
  margin-right: 10px !important;
}
.rm15 {
  margin-right: 15px !important;
}
.rm20 {
  margin-right: 20px !important;
}
.rm40 {
  margin-right: 40px !important;
}
.rm60 {
  margin-right: 60px !important;
}
.rm80 {
  margin-right: 80px !important;
}

.p6 {
  padding: 0 6px !important;
}
.p12 {
  padding: 0 12px !important;
}
.p24 {
  padding: 0 24px !important;
}

.p6-mobile {
  padding: 0 6px !important;
}
.p12-mobile {
  padding: 0 12px !important;
}
.p24-mobile {
  padding: 0 24px !important;
}

.pull-right {
  float: right;
}

// Media Query
@media (max-width: 544px) {
  .navbar {
    &.desktop {
      display: none;
    }
  }
}
@media (min-width: 544px) {
  .navbar {
    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }
}
@media (max-width: 768px) {
}

@media (min-width: 768px) {
  header.head {
    padding: 20px 0;
  }
  .p6-mobile {
    padding: 0 !important;
  }
  .p12-mobile {
    padding: 0 !important;
  }
  .p24-mobile {
    padding: 0 !important;
  }
}

@media (max-width: 992px) {
  section {
    .side-menu {
      display: none !important;
    }
    main.dashboard,
    main.reports,
    main.report,
    main.template,
    main.templates,
    main.createAlert {
      padding-left: 0px;
    }
    .mobile-menu {
      display: block !important;
    }
  }
}
@media (min-width: 993px) {
  section {
    .side-menu {
      display: block !important;
    }
    .mobile-menu {
      display: none !important;
    }
  }
}
@media (min-width: 1200px) {
}
