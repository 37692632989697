@import '../../../../common.scss';

.loading-report-container {
  display: flex;
  margin-top: 20%;
  justify-content: center;
  height: 100%;
}

.report-container {
  .end-alert-button {
    i {
      padding-right: 6px;
    }
  }

  .info {
    color: #828282;
  }

  .recharts-cartesian-axis-line {
    display: none;
  }

  .recharts-cartesian-axis-tick > .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .recharts-cartesian-axis-tick > .recharts-cartesian-axis-tick-value {
    font-size: 11px;
  }

  .hazard {
    font-size: 11px;
    padding-left: 6px;
  }

  .ant-progress.hazard > .ant-progress-inner {
    width: 45px !important;
    height: 45px !important;
    font-size: 16px !important;

    .ant-progress-circle > .ant-progress-circle-path {
      stroke: $color-grey04 !important;
    }
    .ant-progress-text {
      color: $color-grey04 !important;
    }
  }

  .ant-progress.hazard.hazard-level-1 {
    & > .ant-progress-inner {
      .ant-progress-circle > .ant-progress-circle-path {
        stroke: $color-alarm1 !important;
      }
      .ant-progress-text {
        color: $color-alarm1 !important;
      }
    }
  }

  .ant-progress.hazard.hazard-level-2 {
    & > .ant-progress-inner {
      .ant-progress-circle > .ant-progress-circle-path {
        stroke: $color-alarm2 !important;
      }
      .ant-progress-text {
        color: $color-alarm2 !important;
      }
    }
  }

  .ant-progress.hazard.hazard-level-3 {
    & > .ant-progress-inner {
      .ant-progress-circle > .ant-progress-circle-path {
        stroke: $color-alarm3 !important;
      }
      .ant-progress-text {
        color: $color-alarm3 !important;
      }
    }
  }

  .ant-progress.hazard.hazard-level-4 {
    & > .ant-progress-inner {
      width: 45px !important;
      height: 45px !important;
      font-size: 16px !important;

      .ant-progress-circle > .ant-progress-circle-path {
        stroke: $color-alarm4 !important;
      }

      .ant-progress-text {
        color: $color-alarm4 !important;
      }
    }
  }

  .end-alert-button-column,
  .report-info {
    text-align: right;
  }

  .escalated {
    color: #ee6c6c;
  }

  .report-first-row {
    margin-bottom: 10px;
  }

  .report-info-number,
  .title,
  .column-header,
  .user-status {
    font-weight: bold;
  }

  .title {
    font-size: 18px;
  }

  .user-statuses-section-title {
    padding: 20px 0;
  }
}

.report-user-status-info-row {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid $color-grey01;

  & > .user-col {
    flex: 1;
  }

  & > .message-status-col {
    flex: 1;
  }

  & > .action-col {
    width: 100px;
  }
}

.message-status-filter {
  padding: 8px;
  background: white;
  box-shadow: #d3d3d359 0px 0px 3px 2px;
}

.user-status-info-header {
  display: flex;

  & > .user-status-info-name,
  .user-status-info-message-status {
    flex: 1;
  }

  & > .user-status-info-action {
    width: 100px;
  }
}
