@import './variables.scss';

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

// Body reset
body {
  margin: 80px 0 0 0;
  padding: 0;
  font-family: $font-primary;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  label,
  span {
    font-family: $font-primary;
  }
  ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    li {
      font-family: $font-roboto;
    }
  }
}

// Header
header.head {
  background: $color-grey01;
  border-bottom: 1px solid $color-grey02;
  padding: 20px 0;
  height: 80px;
  box-shadow: 0 0 4px $color-grey02;
  z-index: 100;
  position: fixed;
  left: 0 !important;
  right: 0;
  top: 0;
  .navbar {
    justify-content: space-between;
    padding: 0;
    a.navbar-brand {
      font-size: 24px;
      font-weight: 700;
      color: $color-black;
      line-height: 16px;
      span {
        font-size: 11px;
        font-weight: 700;
        color: $color-black !important;
      }
    }
    .pull-right {
      float: right;
    }
    &.mobile {
      padding: 0;
    }
  }
}

// wrapper
.wrapper {
  background: $color-grey01;
  padding: 60px 0;
  .sidebar-mobile {
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 1000;
    padding: 30px;
    display: none;
  }
}

// Typography
h1 {
  font-size: 22px;
  line-height: 1;
  color: $color-black;
  width: 100%;
  margin-bottom: 30px;
  font-weight: 700;
  padding: 0 0 1rem;
  vertical-align: text-top;
  text-transform: uppercase;
  &.display-1 {
    font-size: 36px;
  }
  &.display-2 {
    font-size: 24px;
  }
  &.display-3 {
    font-size: 18px;
    font-weight: 700;
    color: $color-black;
    margin-bottom: 0;
    padding: 0 0 6px;
  }
  &.display-4 {
    font-size: 14px;
    color: $color-black;
    font-weight: 400;
    text-transform: uppercase;
  }
  span {
    color: $color-grey03;
    &.active {
      color: $color-black;
    }
  }
  a.link {
    font-size: 12px;
    text-decoration: none;
    line-height: 1;
  }
}
a.link,
a.icon-link,
a.icon-link i.icon {
  color: $color-blue;
  &:hover {
    color: $color-black;
  }
  &.link-small {
    font-size: 12px;
  }
}

// sidebar-menu
ul.sidebar-menu {
  margin: 0 0 30px;
  li {
    font-size: 14px;
    text-transform: uppercase;
    padding: 0 0 10px 0;
    a {
      color: $color-grey04;
      line-height: 1.8;
      font-family: $font-roboto;
      i {
        margin-right: 12px;
        font-size: 18px;
        vertical-align: middle;
      }
      &:hover,
      &.active {
        color: $color-black;
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}

// Logo
.partner-logo {
  height: 40px;
  float: left;
}

// Buttons
.btn {
  font-family: $font-lato !important;
  padding: 0 24px;
  height: 46px;
  line-height: 46px;
  border-radius: 4px !important;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid $color-grey02;
  color: $color-grey05;
  background: $color-white;
  &.btn-badge {
    padding: 4px 12px 3px;
    height: 26px;
    line-height: 16px;
    font-size: 11px;
    border-radius: 3px !important;
    color: $color-blue;
    border-color: $color-grey01;
    background: $color-grey01;
    margin: 0 12px 12px 0;
    &:hover {
      border-color: $color-grey03;
      background: $color-white;
      box-shadow: 0 0 4px $color-grey03;
    }
    span {
      color: $color-grey04;
    }
  }
  &.btn-secondary {
    border-color: $color-grey03;
    font-size: 11px;
    font-weight: 400;
    color: $color-black;
    &:hover {
      box-shadow: none;
      border-color: $color-blue;
      color: $color-blue;
    }
  }
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    box-shadow: 0 0 6px $color-grey02;
  }
  &.btn-menu {
    border: none;
    padding: 0;
    color: $color-grey05;
    height: 36px;
    line-height: 36px;
    background: transparent;
    i.icon {
      font-size: 24px;
    }
  }
  i {
    font-size: 20px;
    vertical-align: sub;
    margin-left: 12px;

    &.right-margin {
      margin-left: 0px;
      margin-right: 12px;
    }
  }
  &.btn-small {
    height: 36px;
    line-height: 36px;
    i {
      font-size: 18px;
    }
  }
  &.btn-white {
    background-color: $color-white;
    &:hover {
      border-color: $color-grey02;
      color: $color-white;
      background: $color-black;
    }
  }

  &.btn-black {
    border-color: $color-grey04;
    color: $color-white;
    background: $color-black;
    &:hover {
      border: 1px solid $color-grey05;
      color: $color-grey05;
      background-color: $color-white;
    }
  }
  &.btn-grey {
    border-color: $color-grey03;
    color: $color-grey04;
    background: $color-grey01;
    &:hover {
      box-shadow: 0 0 6px $color-grey03;
      color: $color-black;
      border-color: $color-grey03;
    }
  }
  &.btn-red-invert {
    background: $color-red;
    border-color: $color-red;
    color: $color-white;
    text-transform: none;
    font-size: 13px;
    &:hover {
      background: lighten($color-red, 10%);
      box-shadow: none;
    }
  }
  &.btn-red {
    border-color: $color-red;
    color: $color-red;
    &:hover {
      box-shadow: 0 0 6px $color-red;
    }
  }
  &.btn-blue {
    border-color: $color-blue;
    color: $color-blue;
    &:hover {
      box-shadow: 0 0 6px $color-blue;
    }
  }

  &.btn-blue-inverted {
    background: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    text-transform: none;
    font-size: 13px;
    &:hover {
      background: lighten($color-blue, 10%);
      box-shadow: none;
    }
  }
}

.hazard-box {
  margin-bottom: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid $color-grey02;
  .hazard-1,
  .hazard-2,
  .hazard-3,
  .hazard-4 {
    border-left: 10px solid $color-alarm1;
    padding-left: 10px;
    margin-bottom: 12px;
  }
  .hazard-2 {
    border-color: $color-alarm2;
  }
  .hazard-3 {
    border-color: $color-alarm3;
  }
  .hazard-4 {
    border-color: $color-alarm4;
  }
  p {
    font-size: 14px;
    margin: 0;
    padding: 0 0 4px;
    &.text-small {
      font-size: 12px;
    }
  }
  a {
    font-size: 12px;
  }
  ul {
    li {
      font-size: 12px;
      padding-bottom: 6px;
    }
  }
}

// Common
.divider {
  display: block;
  height: 30px;
  width: 100%;
  clear: both;
  &.big {
    height: 60px;
  }
  &.small {
    height: 12px;
  }
  &.line {
    border-bottom: 1px solid $color-grey02;
    margin-bottom: 30px;
  }
}

// form
.form {
  h1 {
    font-size: 14px;
    font-weight: 700;
    color: $color-black;
    line-height: 170%;
    border-bottom: none;
    margin-bottom: 30px;
    span {
      clear: both;
      font-size: 11px;
      font-weight: 400;
      line-height: auto;
      color: $color-black;
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: $color-grey03;
    span {
      font-size: 10px;
    }
    &.active,
    &.active span {
      color: $color-black;
    }
  }
  a.link {
    color: $color-blue;
    font-size: 10px;
    text-decoration: underline;
    font-weight: 700;
  }
  label {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: auto;
    color: $color-black;
  }
  .input-box {
    position: relative;
    input {
      background: $color-grey01;
      border: 1px solid $color-grey02;
      padding: 12px 17px 13px;
      height: 43px;
      font-size: 12px;
      line-height: 43px;
      color: $color-grey05;
      border-radius: 3px;
      margin-bottom: 12px;
      &::placeholder {
        color: $color-grey04;
      }
      &:active,
      &:focus,
      &.done {
        background: $color-white;
        border-color: $color-grey04 !important;
        color: $color-black !important;
        outline: none !important;
      }
      &.new {
        background: $color-grey01;
        border: 1px dashed $color-grey03;
      }
      &.is-invalid {
        border-color: #dc3545;
      }
    }
    .right-box {
      position: absolute;
      top: 13px;
      right: 17px;
      span {
        float: right;
        color: $color-grey03;
        font-size: 12px;
        font-weight: 700;
      }
    }
    .feedback {
      margin: -15px 0 0 15px;
      span {
        font-size: 10px;
        font-weight: 700;
        &.error {
          color: $color-red;
        }
        &.success {
          color: $color-blue;
        }
      }
    }
  }
}

// footer
footer {
  &.main {
    padding: 10px 30px;
    border-top: 1px solid $color-grey02;
    font: $font-lato;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $color-black;
    a.footer-links {
      color: $color-subtext;
      &:hover {
        text-decoration: none;
        color: $color-white;
      }
    }
  }
}

// modal
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  &.mask {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
  }
  .inner-wrapper {
    width: 480px;
    background: white;
    min-height: 320px;
    max-height: 600px;
    opacity: 1;
    z-index: 10002;
    box-shadow: 0 0 20px $color-grey03;
    h1 {
      padding: 30px;
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 700;
      color: $color-grey04;
      i.icon {
        font-size: 32px;
        margin-top: -10px;
      }
    }
    p {
      padding: 15px 30px;
      font-size: 14px;
      line-height: 1.7;
      color: $color-grey04;
    }
    .bottom {
      padding: 30px;
      background: $color-grey01;
    }
  }
}

// bootstrap
textarea.form-control {
  display: block;
  width: 100%;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: $color-grey01 !important;
  border: 1px solid $color-grey02;
  padding: 12px 17px 13px;
  font-size: 12px;
  color: $color-grey05;
  border-radius: 3px;
  margin-bottom: 12px;
  &::placeholder {
    color: $color-grey04;
  }
  &:active,
  &:focus {
    background: $color-white !important;
    border-color: $color-grey05;
    color: $color-black;
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-group {
  display: flex;
  clear: both;
  .btn {
    width: 43px;
    height: 43px;
    padding: 0;
    border-radius: 3px !important;
    margin-right: 10px;
    flex: none;
    line-height: 43px;
    &.btn-alarm1 {
      background: $color-alarm1;
    }
    &.btn-alarm2 {
      background: $color-alarm2;
    }
    &.btn-alarm3 {
      background: $color-alarm3;
    }
    &.btn-alarm4 {
      background: $color-alarm4;
    }
    &.inactive {
      opacity: 0.3;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.badge {
  padding: 4px 12px;
  &.badge-light {
    color: $color-blue;
  }
}

.ant-btn {
  -webkit-transition: none;
  transition: none;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}
