@import '../../common.scss';

.reports-header-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include for-phone-only {
    align-items: flex-start;
    flex-direction: column;
  }

  & > .header-title {
    margin-right: 15px;
    flex-grow: 1;
  }

  & > .search-input {
    width: 300px;

    @include for-phone-only {
      width: 100%;
    }
  }
}

.reports-list-container {
  .spin-container {
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }
}
