.expandable-checkbox-group {
  height: 600px;
  max-height: 600px;
  overflow: auto;
  width: 100%;

  .ant-checkbox-wrapper {
    border-bottom: 1px dashed #f0f0f0;
    display: inline-flex;
    height: 46px;
    width: 100%;

    .ant-checkbox {
      padding-top: 9px;
    }

    span + span {
      width: 100%;
    }

    .user-group {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      margin-top: 5px;
    }
  }
}

.expandable-checkbox-search {
  margin: 24px 0 !important;
}
