@import '../../common.scss';

#usersGroupsSearch,
#selectedUsersGroups {
  background-color: #f7f8fc;
  height: 334px;
  margin-top: 20px;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
}

.ant-collapse.ant-collapse-icon-position-left {
  border: none;
  background-color: #f7f8fc;

  & > .ant-collapse-item {
    border: none;

    & > .ant-collapse-header {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }

    & > .ant-collapse-content {
      background-color: #f7f8fc;
      border: none;
    }
  }
}

.attachment-preview {
  width: auto;
  max-width: 100%;
  max-height: 240px;
}

.inline-select {
  width: auto !important;

  .ant-select-selection {
    border: none;
    display: inline-block;
    padding-left: 6px;
    width: 80px;
  }
}

.user-select-dropdown
  > .ant-select-selection
  > .ant-select-selection__rendered {
  .ant-select-search__field {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
  }
}

.ant-form-item-control.has-error {
  .user-select-dropdown
    > .ant-select-selection
    > .ant-select-selection__rendered {
    &:hover {
      border: 1px solid #f5222d;
      border-radius: 4px;
    }
  }
}

.user-select-dropdown {
  & > .ant-select-selection {
    border: none;
    margin-bottom: 12px;
  }
}

.pagination-button {
  border: none !important;

  &,
  &[disabled] {
    background: transparent !important;
  }
}

.search-header {
  padding-bottom: 20px;
}

.sending-list-button {
  border-radius: 30px;
  color: #828282;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
  &:hover {
    color: $color-blue;
  }

  .sending-list-count {
    background: #2f80ed;
    border-radius: 30px;
    border: 4px solid #fafafa;
    box-sizing: border-box;
    color: white;
    padding: 10px;
    margin-left: 12px;
  }
}

.search-users-groups {
  width: 400px;

  @include for-phone-only {
    width: 100%;
  }
}

#select-users-groups-container {
  padding: 20px;
  background-color: #f7f8fc;

  & > .ant-row.search-bar {
    @include for-phone-only {
      flex-direction: column;

      .ant-col.option {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  #usersGroups {
    background-color: #f7f8fc;
  }

  .ant-select-selection {
    background-color: #f7f8fc;
    color: $color-blue;
  }

  .rows-per-page-select {
    & > .ant-select-selection {
      color: $color-black;
      border: none;
      width: 55px;
    }
  }
}

#alertEndingDateAndTime {
  width: 300px;

  @include for-phone-only {
    width: 100%;
  }
}

.locations-filter {
  width: 300px !important;

  @include for-phone-only {
    width: 100% !important;
  }
}

.form-row {
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    flex-direction: column;
  }

  & > .form-row-col {
    width: 40%;

    @include for-phone-only {
      width: 100%;
    }
  }
}
