@import '../../common.scss';

.ant-input-affix-wrapper.search-input {
  .ant-input {
    border-radius: 12px;
    border: 1px solid;
    border-color: $color-grey03;
    background-color: $color-whitesmoke;

    &::placeholder {
      color: $color-black;
    }

    &:hover {
      border-color: $color-grey04;
    }
  }

  .ant-input-prefix > .anticon-search > svg {
    fill: $color-black;
  }
}
