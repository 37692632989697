@import '../../common.scss';

@mixin charts-breakpoint {
  @media (max-width: 599px) {
    @content;
  }
}

.reports-table {
  .overview {
    color: #5698f1;
    cursor: pointer;
  }
}

.statistics {
  border: 1px solid $color-grey02;
  background: $color-white;
  border-radius: 3px;
  margin: 40px 0 40px 0;

  .select-alert-report {
    margin: 20px;

    .ant-select-selection,
    .ant-select-focused {
      border: none;
      box-shadow: none;
      outline: none;
    }

    .select-alert-report__title {
      font-size: 20px;
      font-weight: bold;
    }

    .select-alert-report__subtitle {
      color: #b7b9c6;
      display: block;
      font-size: 14px;
    }
  }

  .doughnut-charts {
    margin-top: 20px;
    display: flex;

    @include charts-breakpoint {
      flex-direction: column;
    }

    .doughnut-chart {
      display: inline-block;
      width: 50%;

      @include charts-breakpoint {
        width: 100%;
      }
    }
  }

  .statistics-card {
    font-weight: bold;
    height: 100px;
    text-align: center;
    width: 100%;

    @include for-phone-only {
      height: 75px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-grey02;
    }

    &:nth-child(1) {
      @media (max-width: 764px) {
        border-top: 1px solid $color-grey02;
      }
    }

    .statistics-type {
      color: #9ea1b3;
      font-size: 16px;
      padding: 10px 0 10px 0;

      @include for-phone-only {
        font-size: 14px;
      }
    }

    .statistics-metric {
      color: black;
      font-size: 24px;

      @include for-phone-only {
        font-size: 16px;
      }
    }
  }

  .vertical-divider {
    border-left: 1px solid $color-grey02;
  }
}
