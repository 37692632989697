.recharts-cartesian-axis-line {
  display: none;
}

.recharts-cartesian-axis-tick > .recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-cartesian-axis-tick > .recharts-cartesian-axis-tick-value {
  font-size: 11px;
}
