@import '../../common.scss';

.alert-sending-error-notification {
  & .ant-notification-notice-message {
    font-size: 14px !important;
    font-weight: 600;
  }

  & .ant-notification-notice-description {
    font-size: 12px !important;
  }
}

.templates-header-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include for-phone-only {
    align-items: flex-start;
    flex-direction: column;
  }

  & > .header-title {
    margin-right: 15px;
    flex-grow: 1;
  }

  & > .search-input {
    width: 300px;

    @include for-phone-only {
      width: 100%;
    }
  }
}

.templates-list-container {
  .spin-container {
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }
}
