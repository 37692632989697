@import './common.scss';
@import './componentStyles.scss';

@mixin template-list-item-breakpoint {
  @media (max-width: 730px) {
    @content;
  }
}

section {
  &.container {
    &.ant-layout {
      background: $color-white;
      max-width: none;
      width: 90%;

      &>.dashboard.ant-layout-content {
        width: 100%;
      }
    }
  }
}

.ant-layout {
  background: $color-white;
}

.ant-layout-content {
  min-height: 640px;
}

.ant-form-item label {
  text-transform: uppercase;
  font-weight: 700;

  span {
    text-transform: none;
    font-weight: 400;
  }
}

main {

  &.templates,
  &.dashboard,
  &.reports,
  &.report,
  &.locations,
  &.groups,
  &.personnel {
    padding-left: 40px;
  }
}

.template-picker {
  margin: 30px 6px 0 6px;

  .template-box {
    border-radius: 8px;
    border: 1px solid $color-black;
    border-left: 12px solid $color-black;
    box-shadow: 0 0 1px $color-grey04  !important;
    cursor: pointer;
    height: 100px;
    line-height: 1.7;
    margin: 0 16px 16px 0 !important;
    vertical-align: middle;
    width: 148px !important;
    font-size: 11px;
    font-weight: bold;
    opacity: 0.6 !important;
    color: $color-grey03;

    h4 {
      text-align: left !important;
      padding: 10px 0 0 6px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.3 !important;
      color: $color-black;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
      height: 48px;
    }

    &:hover,
    &.template-active {
      opacity: 1 !important;
      box-shadow: 0 0 8px $color-grey03  !important;
      color: $color-black;
    }

    &.hazard-1 {

      &:hover,
      &.template-active {
        opacity: 1 !important;
        box-shadow: 0 0 8px $color-grey03  !important;
        color: $color-black;
        border: 1px solid $color-alarm1;
        border-left: 12px solid $color-alarm1;
      }
    }

    &.hazard-2 {

      &:hover,
      &.template-active {
        opacity: 1 !important;
        box-shadow: 0 0 8px $color-grey03  !important;
        color: $color-black;
        border: 1px solid $color-alarm2;
        border-left: 12px solid $color-alarm2;
      }
    }

    &.hazard-3 {

      &:hover,
      &.template-active {
        opacity: 1 !important;
        box-shadow: 0 0 8px $color-grey03  !important;
        color: $color-black;
        border: 1px solid $color-alarm3;
        border-left: 12px solid $color-alarm3;
      }
    }

    &.hazard-4 {

      &:hover,
      &.template-active {
        opacity: 1 !important;
        box-shadow: 0 0 8px $color-grey03  !important;
        color: $color-black;
        border: 1px solid $color-alarm4;
        border-left: 12px solid $color-alarm4;
      }
    }
  }

  .card {
    margin: 0 16px 16px 0 !important;
    color: $color-grey04;

    &:hover {
      opacity: 1 !important;
      box-shadow: 0 0 8px $color-grey03  !important;
      color: $color-black;
    }

    i.icon,
    .view-all {
      clear: both;
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      font-size: 36px;
      color: $color-blue;
    }

    span {
      display: flex;
      flex-direction: column;
      line-height: 24px;
      color: $color-blue;
    }
  }
}

.ant-tabs-nav {
  text-transform: uppercase;
}

.login-panel {
  width: 400px;
  margin: 100px auto;

  input {
    margin: 10px 0;
  }

  button {
    margin-top: 20px;
    width: 100%;
  }
}

section {

  &.ant-layout,
  &.container.ant-layout,
  .ant-menu {
    background: $color-white;
  }

  .ant-menu {
    padding-right: 24px;
    border-right: 1px solid $color-white;
  }

  .ant-layout-sider-light {
    background: $color-white;
  }

  .side-menu.ant-layout-sider.ant-layout-sider-light {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 100px;
  }

  .side-menu {
    .ant-layout-sider-children {
      padding: 20px;
      height: auto;
      background: $color-whitesmoke;
      border-radius: 6px;
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 100px;
    }

    width: 240px;

    ul {
      background: $color-whitesmoke;
    }

    li {
      border-left: 3px solid $color-whitesmoke;
      width: 160px !important;
      color: $color-subtext;
      background: $color-whitesmoke;

      &:hover {
        border-left: 3px solid $color-whitesmoke;
        background: $color-whitesmoke;
        color: $color-black;
      }

      &.ant-menu-item-selected {
        border-left: 3px solid $color-whitesmoke;
        background: $color-whitesmoke  !important;
        color: $color-black;
        font-weight: bold;
      }
    }
  }
}

.template,
.createAlert,
.createAlertLanding {
  font-size: 11px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 30px 0 0;
    color: $color-black;
  }

  span.subtitle {
    color: $color-subtext;
  }

  &.ant-layout-content {
    padding: 0 !important;
    margin: 0 auto;
    max-width: 675px;
    width: 100%;
  }

  .create-template {
    max-width: 675px;
  }
}

// Tempalte list
.template-list-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include template-list-item-breakpoint {
    flex-direction: column;
    align-items: flex-start;
  }

  .template-list-col {
    display: flex;
    align-items: center;
  }

  .template-list-col:nth-child(2) {
    @include template-list-item-breakpoint {
      margin-top: 15px;
      width: 100%;
      justify-content: space-between;

      .ant-btn.label.ant-btn-sm {
        margin-left: 0;
      }
    }
  }

  .template-list-severity {
    height: 43px;
    width: 43px;
    line-height: 40px;
    border: 2px solid;
    text-align: center;
    border-radius: 100%;
    background: $color-white;

    &.severity-01 {
      border-color: $color-alarm1;

      span {
        color: $color-subtext;
      }
    }

    &.severity-02 {
      border-color: $color-alarm2;

      span {
        color: $color-alarm2;
      }
    }

    &.severity-03 {
      border-color: $color-alarm3;

      span {
        color: $color-alarm3;
      }
    }

    &.severity-04 {
      border-color: $color-alarm4;

      span {
        color: $color-alarm4;
      }
    }

    span {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .template-list-title {
    flex-grow: 1;
    text-align: left;
    margin: 0 6px 0 12px;

    h3.title {
      font-size: 16px;
      margin: 0;
      font-weight: 400;
    }

    span {
      font-size: 12px;
      color: $color-subtext;
      text-transform: none;
    }
  }

  .template-list-options {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 250px;
    align-content: flex-end;
    justify-content: flex-end;

    button.label {
      flex-direction: column;
      display: flex;
      height: auto;
      padding: 6px;
      border: none;
      background: transparent;
      cursor: pointer;
      align-items: center;
      text-transform: none;
      margin: 0 12px;
      box-shadow: none;
      color: $color-subtext;

      .icon {
        font-size: 18px;
      }

      span {
        font-size: 10px;
      }

      &:hover {
        color: $color-blue;
      }

      &:hover span {
        color: $color-blue;
      }
    }
  }

  .template-list-expand {
    padding: 0 4px;

    span.label {
      cursor: pointer;
      font-size: 24px;
    }

    &:hover span {
      color: $color-blue;
    }
  }
}

.according-box {
  padding: 12px 24px;
  margin-bottom: 30px;
  background: $color-whitesmoke;
  border-radius: 3px;

  &:hover {
    box-shadow: 2px 2px 4px $color-alarm1;
  }

  span.severity-info {
    color: #202020;
    font-size: 12px;
    padding: 6px 16px;
    border-radius: 20px;
    margin-right: 20px;
    text-transform: uppercase;
    color: $color-black;
    font-weight: bold;
    float: right;
    height: 32px;
    line-height: 20px;

    &.severity-01 {
      background: $color-alarm1;
    }

    &.severity-02 {
      background: $color-alarm2;
    }

    &.severity-03 {
      background: $color-alarm3;
    }

    &.severity-04 {
      background: $color-alarm4;
    }
  }

  .more {
    text-align: center;
    padding: 6px 16px;
    height: 32px;
    line-height: 20px;

    span.option {
      float: right;
    }
  }

  span {
    font-size: 11px;
    color: $color-subtext;
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;
    color: $color-grey05;
    line-height: 180%;
    margin-top: 12px;
  }

  ul.response {
    margin-top: 12px;

    li {
      color: $color-black;
      background: $color-white;
      height: 32px;
      line-height: 32px;
      border: 1px dashed $color-grey03;
      border-radius: 6px;
      margin-bottom: 6px;
      width: 80%;
      font-size: 12px;
      padding: 0 12px;
    }
  }

  .ant-form-item-label {
    height: 30px !important;

    label {
      margin: 0px !important;
      line-height: 13px;
      font-size: 11px;
      color: $color-subtext  !important;
      font-weight: bold;
    }
  }

  textarea {
    background: $color-white;
  }
}

h3.according {
  margin-bottom: 12px;
  font-size: 14px;

  &.hazard-1 {
    color: $color-alarm1;
  }

  &.hazard-2 {
    color: $color-alarm2;
  }

  &.hazard-3 {
    color: $color-alarm3;
  }

  &.hazard-4 {
    color: $color-alarm4;
  }
}

.list-wrapper {
  padding: 30px;
  border: 1px solid $color-grey03;
  margin-bottom: 30px;
  background: $color-grey01;
  border-radius: 6px;

  .options {
    padding: 12px 0;
  }

  h3.title {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    margin-top: 20px;
  }

  span {
    font-size: 11px;
    color: $color-blue;
  }

  p {
    font-size: 12px;
    color: $color-grey05;
    line-height: 180%;
    margin-top: 20px;
  }

  ul.response {
    margin-top: 20px;

    li {
      color: $color-grey04;
      background: $color-white;
      height: 32px;
      line-height: 32px;
      border: 1px dashed $color-grey03;
      border-radius: 6px;
      margin-bottom: 6px;
      width: 80%;
      font-size: 12px;
      padding: 0 12px;
    }
  }
}

.search-bar {
  padding: 4px;
  display: flex;
  justify-content: space-between;

  .content {
    flex-grow: 1;
  }
}

span.sending {
  font-size: 13px;
  color: $color-black;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 23px;
}

.ant-form-item {
  margin: 0 0 10px;
  color: $color-black;

  .ant-form-item-label {
    label {
      display: inline-block;
      overflow: hidden;
      line-height: 32px;
      white-space: nowrap;
      text-align: right;
      vertical-align: middle;
      font-size: 11px !important;
      margin: 30px 0 0 0;
      color: $color-grey05;
    }
  }

  .ant-form-item-control-wrapper {
    .ant-form-item-control {

      .ant-select-focused .ant-select-selection,
      .ant-select-selection:focus,
      .ant-select-selection:active {
        border-color: none;
        border: none;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      .ant-select-selection--single {
        height: 48px;

        .ant-select-selection__rendered {
          line-height: 48px;
          height: 48px;
          margin: 0px;
          background: $color-whitesmoke;
          border: 1px solid $color-grey03;

          .ant-select-selection-selected-value {
            padding: 0 10px;
          }
        }

        .ant-select-selection__placeholder {
          left: 10px;
          color: $color-grey04;
          font-size: 12px;
        }
      }

      .ant-input:focus {
        background: $color-white;
        border-color: $color-grey04  !important;
        color: $color-black  !important;
        outline: none !important;
      }

      input {
        background: $color-whitesmoke;
        border: 1px solid $color-grey03;
        padding: 12px 17px 13px;
        height: 48px;
        font-size: 12px;
        line-height: 48px;
        color: $color-grey05;
        border-radius: 3px;
        margin-bottom: 12px;

        &::placeholder {
          color: $color-grey04;
        }

        &:active,
        &:focus,
        &.done {
          background: $color-white;
          border-color: $color-grey04  !important;
          color: $color-black  !important;
          outline: none !important;
          box-shadow: none !important;
        }

        &.new {
          background: $color-grey01;
          border: 1px dashed $color-grey03;
        }

        &.is-invalid {
          border-color: #dc3545;
        }
      }

      .ant-form-explain {
        font-size: 10px;
        font-weight: 400;
        color: $color-red;
      }
    }
  }

  textarea.ant-input {
    display: block;
    width: 100%;
    height: 120px;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: $color-whitesmoke  !important;
    border: 1px solid $color-grey03;
    padding: 12px 17px 13px;
    font-size: 12px;
    color: $color-grey05;
    border-radius: 3px;
    margin-bottom: 12px;

    &::placeholder {
      color: $color-grey04;
    }

    &:active,
    &:focus {
      background: $color-white  !important;
      border-color: $color-grey05;
      color: $color-black;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  #hazardLevel .ant-radio-button-wrapper {
    margin-right: 10px;
    border: 1px solid $color-blue;
    border-radius: 24px !important;
    color: black;
    width: 41px;
    height: 41px;
    vertical-align: middle;
    text-align: center;
    line-height: 41px;
  }

  .selected-users-group,
  .selected-users-groups {
    background-color: #f7f8fc;
    height: 420px;
    margin-top: 20px;
    overflow-y: overlay;
    padding: 12px 24px;
    width: 100%;
    border-radius: 6px;

    @include for-phone-only {
      padding: 12px 0;
    }
  }

  .ant-checkbox-group {
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    margin-bottom: 30px;

    &::-webkit-scrollbar-track {
      border: 1px solid #000;
      padding: 2px 0;
      background-color: $color-blue;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #737272;
      border: 1px solid $color-blue;
    }

    .ant-checkbox-wrapper+.ant-checkbox-wrapper {
      margin: 0;
    }

    .ant-checkbox-wrapper {
      height: 46px;
      border-bottom: 1px dashed #f0f0f0;
      line-height: 40px;
      padding-bottom: 12px;

      .ant-checkbox {
        cursor: pointer;
        position: absolute;
        top: 18px;
      }

      .user-group {
        display: inline-flex;
        flex-grow: 2;
        justify-content: space-between;
        width: calc(100% - 44px);
        position: absolute;
        top: 5px;
        left: 26px;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          min-width: 60px;
          padding-right: 30px;
        }

        svg {
          font-size: 18px;
          width: 24px;
          text-align: center;
          fill: $color-grey04;
        }
      }
    }
  }
}

.ant-input-affix-wrapper {
  height: 48px;
  margin-bottom: 12px;

  input.ant-input {
    background: $color-grey01;
    border: 1px solid $color-grey02;
    padding: 12px 17px 13px;
    height: 48px;
    font-size: 12px;
    line-height: 48px;
    color: $color-grey05;
    border-radius: 3px;

    &::placeholder {
      color: $color-grey04;
    }

    &:active,
    &:focus,
    &.done {
      background: $color-white;
      border-color: $color-grey04  !important;
      color: $color-black  !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

p.summary {
  color: $color-black;
  font-family: $font-lato;
  font-size: 13px;
  line-height: 22px;
  padding: 12px 24px 12px 0;
}

.label {
  &.label-title {
    font-size: 12px;
    text-transform: uppercase;
    margin: 30px 0 10px;
    font-weight: bold;
    color: $color-subtext;
  }

  &.label-item {
    font-size: 13px;
    margin: 12px 20px 0 0;
    font-weight: 400;
    color: $color-grey05;
  }
}

.sending-to-data {
  margin: 12px 0;

  .user-group {
    height: 36px;
    border-bottom: 1px dashed $color-grey03;
    line-height: 36px;
    color: $color-black;
    font-family: $font-lato;

    span {
      &.small {
        font-size: 11px;
        color: $color-grey05;
        margin-left: 8px;
        color: $color-grey05  !important;
      }
    }

    i.icon {
      font-size: 18px;
      width: 24px;
      text-align: center;
      color: $color-grey04;
    }
  }
}

.sending-no-box {
  display: flex;
  justify-content: flex-end;
  height: 48px;
  /* eAlarms_ grey02 */
  border: 1px solid $color-grey02;
  border-radius: 30px;
  line-height: 48px;
  margin-bottom: 20px;
  float: right;
  color: $color-blue;

  &:hover {
    cursor: grab;
    background: $color-grey01;
    border: 1px solid $color-grey03;
  }

  .label {
    padding: 0 10px 0 20px;
  }

  .number {
    background: $color-blue;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 13px;
    height: 41px;
    line-height: 37px;
    padding: 0 18px;
    color: $color-white;
    font-weight: bold;
    border: 3px solid $color-grey01;
  }
}

ul.summary-list {
  margin: 12px 0;

  li {
    height: 36px;
    border-bottom: 1px dashed #f0f0f0;
    line-height: 36px;
    color: $color-black;
    font-family: $font-lato;

    span {
      &.small {
        font-size: 11px;
        color: $color-grey05;
        margin-left: 8px;
        color: $color-grey05  !important;
      }
    }

    i.icon {
      font-size: 18px;
      width: 24px;
      text-align: center;
      color: $color-grey04;
    }
  }

  &.response {
    display: flex;
    flex-direction: column;

    li {
      border: 1px dashed $color-grey03;
      border-radius: 2px;
      margin-bottom: 12px;
      font-size: 12px;
      padding: 8px 12px;
      line-height: 16px;
      height: auto;
      background: $color-white;

      &:hover {
        background-position: right center;
      }
    }
  }

  &.locations {
    display: flex;
    flex-direction: column;

    li {
      height: 26px;
      line-height: 24px;
      flex-wrap: wrap;
      border-radius: 6px;
      margin: 0 12px 12px 0;
      font-size: 13px;
      border-bottom: 1px dashed $color-grey03;
      width: 80%;
    }
  }
}

.ant-upload-list {
  width: 300px;
  float: right;
  display: inline-block;

  .ant-upload-list-item {
    padding: 0 8px;
    border: none;
    border-radius: 4px;

    img {
      border: 1px solid $color-grey03;
      padding: 4px;
    }
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30;
  width: 100%;
  z-index: 100;
  padding: 12px;
}

.css-vcvh4u {
  background: $color-grey03;
  height: 1px;
  margin: 30px 0 !important;
  border: none !important;
}

.css-za0dbp,
.css-dphgat {
  font-weight: bold;
}

.ant-upload {
  margin-top: 20px;
}

.stat-content {
  display: flex;
  flex-wrap: wrap;

  .stat-box {
    width: 100%;
  }

  .stat-box:nth-child(2) {
    @include for-phone-only {
      margin-top: 25px;
    }
  }
}

.alert-info-box {
  padding: 20px;
  border: 1px solid $color-grey02;
  background: $color-whitesmoke;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  div {
    width: 18%;
    margin: 1%;
    box-sizing: border-box;
    background: white;
    border-radius: 3px;
    padding: 2% 0;
  }
}

.alert-section-box {
  height: 36px;
  margin: 80px 0 20px;
}

.group-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  padding: 0;
  float: left;
  line-height: 36px;
}

.ant-modal-content {
  .ant-modal-body {
    padding: 60px;

    .expandable-checkbox-group {
      height: 320px !important;
    }
  }
}

.report.ant-layout-content {
  width: 100%;
}

.reports,
.report,
.dashboard {
  .reports-table {
    .ant-table {
      font-size: 14px;
      font-weight: bold;
      color: $color-black;
      background: $color-white;
      border-radius: 12px;
      border: 1px solid $color-grey03;
    }

    .ant-table-tbody>tr:last-child>td {
      border-bottom: none !important;
    }

    .ant-table-thead>tr>th {
      background: transparent;
      padding: 24px 16px 12px;
      font-size: 12px;
      font-weight: bold;
      color: $color-subtext;

      &:hover {
        background: $color-grey01  !important;
      }

      .anticon-filter {
        left: 120px !important;
      }
    }

    .ant-table-tbody>tr>td {
      font-size: 14px;
      color: $color-black;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
      vertical-align: top;

      &.col-severity {
        text-align: center;
        width: 140px;
      }

      &.col-overview {
        text-align: center;
        width: 120px;
      }

      .user-full-name {}

      .user-title,
      .user-department,
      .user-location {
        font-size: 12px;
        font-weight: normal;
        color: $color-grey04;
        margin-right: 12px;
      }

      &.col-escalated {
        width: 180px;
        text-align: center !important;

        .custom-message-escalated {
          i {
            font-size: 32px;
            color: $color-msg-escalated;
            line-height: 32px;
          }
        }

        .escalate-button {
          color: #202020;
          font-size: 12px;
          padding: 6px 16px;
          border-radius: 20px;
          background: #ffffff;
          border: 1px solid #dedfea;
          height: 32px;
          line-height: 20px;

          &:hover {
            box-shadow: 0 0 0 $color-white;
            border-color: $color-blue;
            color: $color-blue;
          }
        }
      }

      &.col-msg-status {
        .message-status {
          display: flex;
          align-items: center;

          .user-status {
            text-transform: lowercase !important;
            padding: 2px 0px 3px;
            float: left;
            margin-right: 12px;
            border-radius: 3px;

            &.user-status-scheduled {
              background: $color-msg-scheduled;
            }

            &.user-status-sent {
              background: $color-msg-sent;
            }

            &.user-status-delivered {
              background: $color-msg-delivered;
            }

            &.user-status-read {
              background: $color-msg-read;
            }

            &.user-status-responded {
              background: $color-msg-responded;
            }

            &.user-status-escalated {
              background: $color-msg-escalated;
            }
          }

          .msg-age {
            line-height: 28px !important;
            font-size: 10px;
            font-weight: normal;
            color: $color-grey04;
            margin-right: 12px;
          }
        }
      }
    }

    .table-list {
      &.center {
        text-align: center;
      }

      span {
        font-size: 14px;
        color: $color-black;

        &.reports-hazard-level {
          color: $color-black;
          font-size: 12px;
          padding: 4px 16px;
          border-radius: 20px;

          &.hazard-level-1 {
            background: $color-alarm1;
          }

          &.hazard-level-2 {
            background: $color-alarm2;
          }

          &.hazard-level-3 {
            background: $color-alarm3;
          }

          &.hazard-level-4 {
            background: $color-alarm4;
          }
        }

        &.small {
          font-size: 12px;
          font-weight: normal;
          color: $color-grey04;
        }

        &.link {
          color: $color-blue;
          font-weight: normal;
        }
      }
    }
  }
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
  overflow-x: visible !important;
}

.inline-select.ant-select.ant-select-enabled {
  margin-left: 12px;
}

#hazardLevel {
  .ant-radio-button-wrapper {
    margin-right: 10px;
    border: 1px solid white;
    border-radius: 4px !important;
    color: black;
    opacity: 0.3;

    &::before {
      background: none;
    }

    &:nth-child(1) {
      background-color: $color-alarm1  !important;
    }

    &:nth-child(2) {
      background-color: $color-alarm2  !important;
    }

    &:nth-child(3) {
      background-color: $color-alarm3  !important;
    }

    &:nth-child(4) {
      background-color: $color-alarm4  !important;
    }
  }

  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:hover {
    border: 1px solid black !important;
    box-shadow: none;
    color: black;
    opacity: 1;
  }
}

.hoverable {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ant-tabs-bar.ant-tabs-top-bar {
  border: none;

  .ant-tabs-tab {
    padding: 0;
    padding-bottom: 5px;
  }
}

.ant-tag {
  background-color: $color-white;
  font-weight: 400;
  font-size: 10px;
}

.section-title {
  font-size: 12px;
  font-weight: 700;
  display: block;
  line-height: 18px;
}

.section-subtitle {
  font-size: 10px;
  font-weight: 500;
  display: block;
  color: $color-grey04;
}

.text-bold {
  font-weight: 700;
}

.text-medium {
  font-size: 14px;
}

.text-small {
  font-size: 12px;
}

.text-secondary {
  color: $color-grey04;
}

.user-response-bar {
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 7px;
  margin-top: 7px;
  border-radius: 4px;
  background-color: $color-white;
  border: 1px solid $color-grey03;
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &>.ant-progress {
    height: 100%;
    position: absolute;

    &>div {
      height: 100%;

      &>.ant-progress-outer {
        height: 100%;

        &>.ant-progress-inner {
          height: 100%;
          border-radius: 0;
          background-color: $color-white;

          &>.ant-progress-bg {
            height: 100% !important;
            border-radius: 0;
            background-color: $color-grey02;
          }
        }
      }
    }
  }

  &>.user-response-bar-overlay {
    position: absolute;
  }

  &>.user-response-bar-name {
    font-size: 12px;
    margin-left: 10px;
    z-index: 1;
  }

  &>.user-response-bar-count {
    font-weight: 700;
    margin-right: 10px;
    color: $color-grey04;
    z-index: 1;
  }
}

.sending-list-button {
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: space-between;
  margin: 0 0 0 20px !important;
  height: 46px;

  &>.sending-list-tag {
    width: 50px;
    height: 38px;
    border-radius: 25px;
    background-color: $color-blue;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &>.sending-list-title {
    color: $color-blue;
    margin-left: 10px;
  }
}

.back-list-button {
  display: flex;
  align-items: center;
  background-color: $color-white;
  justify-content: space-between;
  margin-left: 20px;
  height: 46px;
  border: 1px solid $color-subtext  !important;

  i {
    margin-right: 12px;
  }
}

.ant-select-selection--single {
  height: 48px;
  line-height: 48px;
  background: $color-whitesmoke;
  border: 1px solid $color-grey03;
}

.ant-select-no-arrow .ant-select-selection__rendered {
  line-height: 48px;
}

.alert-view {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 60px 0;
  background: $color-whitesmoke;

  .box-top {
    width: 100%;
    padding: 30px 24px 32px;
  }

  .box-content {
    display: flex;
    flex-direction: column;
    padding: 12px 24px;

    .inner-50 {
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px 0 0;
    }

    .inner {
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px 0 0;
    }
  }
}

.template-box {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0;
  padding: 24px;
  background: $color-whitesmoke;
}

h2.summary {
  font-size: 16px;
  color: $color-black;
  font-weight: 700;
}

.ant-input {
  background: $color-grey02;
  border: 1px solid $color-grey03;
  padding: 12px 17px 13px;
  height: 48px;
  font-size: 13px;
  line-height: 48px;
  color: $color-grey05;
  border-radius: 3px;

  &::placeholder {
    color: $color-grey04;
  }

  &:active,
  &:focus,
  &.done {
    background: $color-white;
    border-color: $color-grey04  !important;
    color: $color-black  !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &.new {
    background: $color-grey02;
    border: 1px dashed $color-grey03;
  }

  &.is-invalid {
    border-color: #dc3545;
  }
}

.ant-list-header {
  margin-bottom: 12px;
  line-height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  a {
    float: right;
  }
}

.ant-list-items {
  .alert-item-row-title {
    font-size: large;
    color: $color-black;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .alert-item-row-stats {
    font-size: small;
    color: $color-subtext;
    display: flex;
    flex-direction: column;

    .item-box {
      margin-right: 30px;

      .item-stat {
        color: $color-grey05;
        margin-bottom: 6px;
      }
    }
  }

  .view-report-col button.label {
    &:hover {
      color: $color-blue;
    }

    &:hover span {
      color: $color-blue;
    }
  }
}

.select-alert-report {
  width: 300px;

  .ant-select-selection--single {
    height: auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    position: relative;
    background: white;

    .ant-select-selection__rendered {
      padding: 12px;
      margin-right: 30px;
      width: 250px;
    }

    i {
      position: absolute;
      top: -16px;
      right: 5px;
    }
  }
}

.ant-select-dropdown.ant-select-dropdown--single {
  @include for-phone-only {
    width: 250px;
  }
}

.pageHeader {
  display: flex;
  border-radius: 3px;
  align-items: center;
  align-content: center;
  margin-bottom: 30px;

  h3.ant-typography,
  .ant-typography h3 {
    margin-bottom: 0;
  }

  .ant-input-affix-wrapper {
    margin-bottom: 0;
    height: 46px;
  }
}

@media (min-width: 768px) {
  .stat-content {
    justify-content: space-between;
  }

  .stat-content .stat-box {
    width: 46%;
  }

  .alert-view .box-content {
    flex-direction: row;
  }
}

@media (min-width: 622px) {
  .ant-list-items .alert-item-row-stats {
    flex-direction: row;
  }
}

@media (max-width: 450px) {
  .ant-layout {
    padding: 20px 0 !important;
  }
}