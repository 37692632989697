.wire-flex-container {
  display: flex;

  &.flex-container-direction-row {
    flex-direction: row;
  }

  &.flex-container-direction-column {
    flex-direction: column;
  }

  &.flex-container-align-center {
    align-items: center;
  }
}
